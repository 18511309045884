import React from 'react';
import { useResource } from 'retil';
import { newsItem } from '../../resources/news';
import { HeroHeader, Footer } from '../../components';
import { Link } from 'react-navi';
import { SectionTitle } from '@2020-contest-web/components/dist/components';

interface NewsAppProps {
  newsId: string;
}

export function NewsApp({ newsId }: NewsAppProps) {
  const [state] = useResource(newsItem, newsId);

  return (
    <main>
      <HeroHeader />
      <section className="section has-background-white">
        <div className="container" style={{ maxWidth: 720 }}>
          <div className="is-margin-bottom-3">
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered is-margin-bottom-2">News</h2>
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered is-margin-bottom-1 is-size-6">
              {state.data.title}
            </h2>
            <h2 className="is-size-4 has-text-futura-pt has-text-italic has-text-centered is-margin-bottom-2 is-size-7">
              {state.data.publishedAt.toDateString()}
            </h2>
          </div>
          <div className="theme-box has-text-centered is-margin-bottom-2-5">
            <div>
              <figure className="image is-margin-bottom-3">
                {state.data.image ? <img src={state.data.image.url} /> : <img src="/img/bg/theme@2x.jpg" />}
              </figure>
              <div
                className="content has-text-left is-line-height-2-5"
                dangerouslySetInnerHTML={{ __html: state.data.content ?? '' }}
              />
            </div>
          </div>
          <nav className="breadcrumb is-centered" aria-label="breadcrumbs">
            <ul>
              <li>
                <Link href="/">
                  <strong className="has-text-marker">トップに戻る</strong>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </section>
      <Footer />
    </main>
  );
}
