import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-navi';
import {Entry} from "@2020-contest-web/components/src/types";
import {Photo} from "@2020-contest-web/components/src/components";

interface EntryCardProps {
    entry: Entry;
    onlive: boolean;
}

export function EntryCard({ entry, onlive, className, href }: EntryCardProps & Link.Props) {
    return (
        <div className={classNames('entry-card', className)}>
            <Link href={href}>
                <Photo
                    className={classNames({
                        'is-onlive': onlive
                    })}
                    src={`/img/entry/${entry.entry_id}/1.jpg`}
                    alt={entry.public_name ?? ""}
                >
                    <figcaption className="entry-card-mask">
                        <figure className="image is-1by1">
                            <figcaption>
                                <p className="has-text-weight-bold">詳しく見る</p>
                            </figcaption>
                        </figure>
                    </figcaption>
                    <div className="entry-card-prize">
                        {entry.entry_id === 3 && (
                            <p className="is-warning is-size-7-mobile is-size-5 has-text-futura-pt has-text-weight-bold has-text-italic tag">グランプリ</p>
                        )}
                        {entry.entry_id === 4 && (
                            <p className="is-size-5 is-size-7-mobile has-text-futura-pt has-text-weight-bold has-text-italic tag">準グランプリ</p>
                        )}

                    </div>
                </Photo>
                <div className="entry-card-title">
                    <p className="entry-card-title-name">{entry.public_name ?? ""}</p>
                    <p className="entry-card-title-university">{entry.faculty} {entry.grade}</p>
                </div>
            </Link>
        </div>
    );
}
