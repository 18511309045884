import { createDocumentResource, createQueryResource, list } from 'retil';
import { Entry } from '@2020-contest-web/components/src/types';

export const entry = createDocumentResource<Entry>('entry');
export const entryList = createQueryResource('entryList', {
  for: list(entry),
  load: async () => {
    return [
      {
        id: 1,
        entry_id: 1,
        block: 'A',
        stage: '1',
        public_name: '髙橋 彩里',
        "faculty": "国際教養学部国際教養学科",
        "grade": "4年",
        twitter: "sophians20_no1",
        instagram: "sophians20___no1",
        "birth-day": "1998/04/22",
        "hometown": "オーストラリア",
        "hobby": "ヴァイオリン、クラシックバレエ、水泳、歌、お料理",
        "interview1": "今まで何度か委員の方に声をかけて頂いたことがあったのですが出場する勇気が有りませんでした。しかし、今年のコンテストはガラッと変わってとても新しいものだったので、挑戦してみたいなと思い応募しました。そして、私は社会的影響力のあるモデルになることが夢です。この社会的影響力があるという意味は、社会を少しでもより良い方向に動かしたい！ということです。そこで、このソフィアンズコンテストを通して自分の思い描くインフルエンサー像を軸に活動したいです。",
        "interview2": "様々な社会問題に触れ自分自身の考えを深めたいです。SDGs部門において、発信するには、自身の知識もかなり必要になると思います。まだまだ未熟なので、社会情勢や自分の興味のあるものをとことん勉強し、SNS等を通じて発信するからには一番の理解者になれる様に努力したいです。",
        "interview3": "今回のソフィアンズコンテストはルッキズムの廃止、外見至上主義を廃止するというコンセプトが掲げられています。世界で最も美しい顔に対して水原希子さんがコメントしていたように、今、外見至上主義は大きな話題の一つになっています。私は内面から溢れ出る美しさこそが、その人の美しさにつながると考えています。今回の新しいコンテストを通して、新しい美の形を追求し発信したいです。",
        "interview4": "175cmある身長です！身長が高いことから一時期はコンプレックスに感じていたこともありました。しかし今はこの身長こそ自分の武器にしたいと思っています。",
        "interview5": "無所属",
        "interview6": "スターバックスコーヒージャパン(2018年8月退社)女性専用ジムでのトレーナー",
        "interview7": "私の所属する国際教養学部が自慢です。多様なバックグラウンドをもった生徒が集まる国教は、本当に刺激が多いんですよ。授業のディスカッションであっても、自分では考えもつかない意見が飛び交うことも。視野が広い生徒と切磋琢磨し、成長できる環境が広がっています！",
        "interview8": "誰にでも簡単に取り組めるワークアウトメニューの開発です！私は女性向けジムのトレーナーのアルバイトをしています。筋トレと聞くと難しい・キツいイメージがある方もいらっしゃると思います。自重トレーニングなどはおうちでも取り組めるものもあり意外と簡単なんですよ！これを読んでいる皆さまにも簡単なメニューを考えてお届けしたいです。",
        "interview9": "お料理にはまっています！動画制作にも挑戦しました。自粛中は行きたいカフェのメニューを真似してお料理をしたり、夜ご飯を作っていました。あえてグルテンフリーケーキを作っていたはずなのに、作ることが楽しくて沢山作ってしまい、少し太ってしまいました笑",
        "interview11": "自分を知ること、認めること。",
        "interview10": "森星さんです。彼女のように立ち振る舞いや行動まで \"sparkle\" = 輝けるような女性 になりたいです！",
        "interview12": "今年から新しくできたコンテストの一員として、参加できることにとても楽しみにしています。実行委員、そしてファイナリストの皆さんで意見を出し合い、互いに高め合いながら、ソフィアンズコンテストのスタートを切れたらなと思っております。新たな歴史を作る気持ちで、ファイナリスト期間を過ごしたいと思います。宜しくお願い致します！！",
      },
      {
        id: 2,
        entry_id: 2,
        block: 'A',
        stage: '1',
        public_name: '田中 哲哉',
        "faculty": "理工学部物質生命理工学科",
        "grade": "3年",
        twitter: "sophians20_no2",
        instagram: "sophians20____no2",
        "birth-day": "1999/03/08",
        "hometown": "大阪府",
        "hobby": "サーフィン、スケートボード",
        "interview1": "大好きな海を守っていくために地元の仲間たちとビーチクリーン活動などを行なっている中で、多くの人に現状を発信し、意識を変えていく必要があると感じたからです。",
        "interview2": "自分は海洋汚染をはじめとした環境問題に興味があるのでSDGS部門などは特に頑張りたいです。",
        "interview3": "自然(環境)を思いやることの重要性",
        "interview4": "明るさ、高身長",
        "interview5": "体育会　ウインドサーフィン部",
        "interview6": "ハンバーガー屋",
        "interview7": "志の高い人が多いところ",
        "interview8": "ビーチクリーン",
        "interview9": "Netflixで映画鑑賞 Apple musicで音楽鑑賞",
        "interview11": "毎日成長",
        "interview10": "イチロー",
        "interview12": "はじめまして！大好きな海を綺麗に守っていくために海洋汚染をはじめとした環境問題を中心に発信し、少しでも興味を持つ仲間が増えたら嬉しいです。自分の発信をキッカケに、ちょっとした行動や考え方の変化に繋がる事が理想の形です。精一杯頑張りますので応援の程よろしくお願い致します。",
      },
      {
        id: 3,
        entry_id: 3,
        block: 'A',
        stage: '1',
        public_name: '吉開 優姫',
        "faculty": "法学部地球環境法学科",
        "grade": "2年",
        twitter: "sophians20_no3",
        instagram: "sophians20___no3",
        "birth-day": "2000/07/18",
        "hometown": "神奈川県",
        "hobby": "コスメ収集、料理、野良猫の写真を撮る、四つ葉のクローバーを見つける、ダンス、バトントワリング",
        "interview1": "自分を客観視することにより自身を見つめ直す機会を作り、ステップアップをしたいと思ったからです。また、当コンテストに出場することで自らの声が届く範囲が広がり、ソーシャルイシューに関しての発信も人々に届けやすくなると考えます。それをいつかではなく、今やってみようと思いました！",
        "interview2": "私はこれまでダンスなどを通じて自分自身を表現する活動をしてきました。ですが、これからはもっと広範なことを表現したいとの目標を持ちました。多様性を尊重するというコンテストの理念のもと、社会課題の発信を積極的に行っていきたいです。",
        "interview3": "多くの人は上智大学に対して国際的なイメージがあると思います。国際色豊かな魅力はもちろんのこと、私のように環境法を学んだり、多種多様な学生がいることもぜひ知って欲しいです！！",
        "interview4": "一度自分でやると決めたことは、必ず最後までやり遂げる精神力があります。",
        "interview5": "SPH mellmuseでアイドルパフォーマンスをしています",
        "interview6": "ドラッグストアで働いています",
        "interview7": "私が所属する法学部地球環境法学科は日本全体で見ても数少ない、環境法に特化した学科なんです。法的な観点から環境問題を紐解いていくような、興味深く充実した学習が出来ます！",
        "interview8": "メイクやコスメについて研究すること",
        "interview9": "料理をしたり、ドラマを観たりしていました！",
        "interview11": "初志貫徹",
        "interview10": "家族です。とても頼りになり、私の人生においてそれぞれがかけがけのない存在です。",
        "interview12": "見える上智大生として大学の魅力や社会課題を発信しつつ、自分と異なるものを恐れない勇気を持って、新たな出会いを楽しみたいと思っています。貴重な機会を頂けたからには応援して下さる方々や友人、家族を大切に最後まで走り抜けます！宜しくお願いします！",
      },
      {
        id: 4,
        entry_id: 4,
        block: 'A',
        stage: '1',
        public_name: '三好 百花',
        "faculty": "文学部フランス文学科",
        "grade": "4年",
        twitter: "sophians20_no4",
        instagram: "sophians20___no4",
        "birth-day": "1998/11/17",
        "hometown": "神奈川県",
        "hobby": "趣味: 旅行、美術鑑賞、ライブ鑑賞\n特技: フランス語、卓球、動画編集",
        "interview1": "昨年のミスコンの一企画に制作者として携わった際、候補者の方々の成長を間近で見ていて、コンテストの魅力を強く感じたためです。",
        "interview2": "自信をもって自分自身や社会課題を発信できるよう努めます。",
        "interview3": "個性豊かなファイナリストのみんなと切磋琢磨しながら、自身の魅力を独自の方法で伝えたいです。",
        "interview4": "チャレンジ精神です！コンテストでの挑戦を通じて一歩一歩成長していきたいと思います。",
        "interview5": "放送研究会(昨年冬に引退しました！)",
        "interview6": "テレビ局でアルバイトをしています。",
        "interview7": "仏文科の先生方はチャーミングで仏様のように優しいです。",
        "interview8": "『PRESIDENT』という雑誌を読むこと、断捨離",
        "interview9": "友人とzoom会、読書、愛犬と遊ぶetc...",
        "interview11": "継続は力なり、Do my best.",
        "interview10": "家族、友人、安住紳一郎アナウンサー、ハロプロメンバー",
        "interview12": "大きな成長を遂げられるよう努力と挑戦を続けながら、メンバー一丸となって記念すべき第一回目のコンテストを盛り上げていきたいと思います！応援宜しくお願い致します！",
      },
      {
        id: 5,
        entry_id: 5,
        block: 'A',
        stage: '1',
        public_name: '北脇 里紗',
        "faculty": "国際教養学部国際教養学科",
        "grade": "4年",
        twitter: "sophians20_no5",
        instagram: "sophians20___no5",
        "birth-day":"1998/10/22",
        "hometown": "大阪府",
        "hobby": "趣味: フランス語、ダンス、フルート、ヨガ、旅行 特技: 英語、乗馬",
        "interview1": "自分自身を将来へ向かって成長させたく、また、グローバル化や多様化に伴った新しい形のコンテストに興味を持ったからです。",
        "interview2": "自分らしく個人の魅力や社会課題を発信していくことです。",
        "interview3": "私は今年大学主催のNY国連研修に参加をしました。その経験で得た知識を活かしつつ、まだまだ未知な事が沢山あると感じたので、様々な事に関心を向け学び、皆様と共有していきたいです。",
        "interview4": "よく食べ、よく笑う！",
        "interview5": "無所属",
        "interview6": "美容室のレセプショニスト",
        "interview7": "国際教養学部は様々な分野の授業がとれる。英語が堪能で、個性豊かな人が多くアットホームな感じでみんな仲が良い^ ^",
        "interview8": "グルテンフリー/ビーガンスイーツ作り",
        "interview9": "毎朝の日課としてヨガを始めた！家族とお家お好み焼き",
        "interview11": "Treat others as you want to be treated ",
        "interview10": "芯を強く持ち、前向きに物事に取り組む人",
        "interview12": "世界が大変な状況下にある中、私がこのコンテストを通じてメディアに発信をすることで、1人でも多くの人に笑顔やハッピーになってもらいたいです。また、上智大学生としてグローバルな社会問題を皆さんと一緒に取り組めたらいいなと思います。自分らしく精一杯頑張りますので応援宜しくお願い致します(^ ^)",
      },
      {
        id: 6,
        entry_id: 6,
        block: 'A',
        stage: '1',
        public_name: '山本 大葵',
        "faculty": "文学部フランス文学科",
        "grade": "3年",
        twitter: "sophians20_no6",
        instagram: "sophians20___no6",
        "birth-day": "1999/08/05",
        "hometown": "富山県・栃木県",
        "hobby": "趣味：掃除・整頓・メンズメイク 特技：人前で話すこと・バーテンダーのお仕事",
        "interview1": "ルッキズムの環境下では評価されがたい候補者の内面や”想い”という部分に焦点を当てている素晴らしいコンテストだと感じました。だからこそこのコンテストを通じて、自分の将来の夢の実現に向けて大きな一歩を踏み出せると思ったため出場を決めました！",
        "interview2": "自分もまだまだ社会課題について勉強中の身です。なので自分なりの意見を発信するとともに、皆さんのご意見も頂きながら共に社会課題についての理解を深めていきたいと考えています！",
        "interview3": "あなたは何者ですか？と問われたときに答えられない。人生で成し遂げたいことはなんですか？と問われたときに答えられない。この現状を当たり前のままにしてはいけないと伝えたいです。",
        "interview4": "楽観的・建設的・冷めてるようで情熱的",
        "interview5": "SISEC・Oreve双方で上智大学の代表をさせていただいています！",
        "interview6": "未熟ながらバーテンダーをさせていただいています。ビールが一番好きです。",
        "interview7": "やっぱりこのコンテストを開くだけあって多様性に富んだ大学であるとは思います。様々なバックグラウンドを持っている人間が世界中から集まっていますし、そして良くも悪くもキャンパスがそこまで広くないので、3歩歩けば外国人に出会えるというのはとても恵まれた環境にいたなと、何歩歩いても自分にしか出会えない自粛期間中に痛感していますね（笑）",
        "interview8": "日本文化に浸ること・メンズメイク",
        "interview9": "友人と会社を立ててました！自分達なりの社会をよりよくしたいという思いが、現実に形になっていくことにはすごく充実感を感じました。",
        "interview11": "汝自身を知れ・脱皮",
        "interview10": "譲れない自分の軸とスタイル、知性を持っているかっこいい人ですね。伊勢谷友介　窪塚洋介　金子ノブアキ　駒井大樹　などなど",
        "interview12": "口だけにしないことです。多様性を語るこのコンテストが結果的に従来のものと同じという評価を受ける。SNSで発信するだけで何も行動に起こさない。こういったことを避けるために、小さくてもいい、この3か月半で実際に社会がいい方向に進むための施策を打ちたいと思っています。",
      },
    ];
  },
});
